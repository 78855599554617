<template lang="pug">
footer.footer
    .footer__content
      .footer__info
        p.bold {{ $t('footer.contacts') }}
        .footer__contacts
          p.email Email:#[br]
            a(href="mailto:info@multiratefx.com") info@multiratefx.com
            br
            a(href="mailto:multiratefx@gmail.com") multiratefx@gmail.com
          p.phone {{ $t('footer.phone') }}#[br]
            a(href="tel:+79163728982") +7(916)-372-89-82
      .footer__info
        p.bold {{ $t('footer.workingHours') }}
        p {{ $t('footer.workingDays') }}#[br]{{ $t('footer.weekDays') }}
      .footer__info
        p.bold {{ $t('footer.address') }}
        p Belize, Belize City, 56 Daily Street, Belize District, P.O. Box 1825
      p.copyright Multirate Limited, all rights reserved, {{ currentYear }}
    .footer__risk
      p.risk.bold {{ $t('footer.riskTitle') }}
      p.risk {{ $t('footer.riskText') }}

</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
    background: $wafer;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    &__content {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }

    &__contacts {
      display: flex;
      p {
        margin-top: 0;
        &:nth-child(1) {
          margin-right: 20px;
        }
      }
    }

    .copyright {
      align-self: flex-end;
    }

    &__risk {
      padding: 24px;
    }
}
</style>
