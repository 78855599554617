<template lang="pug">
section.meta-trader.content-area
    h1 {{ $t('pages.metaTrader.title') }}
    h2 {{ $t('pages.metaTrader.subtitle') }}
    p {{ $t('pages.metaTrader.description') }}

</template>

<script>
export default {
  name: 'MetaTrader',
    mounted() {
        document.title = this.$t('pages.metaTrader.metaTitle')
    }
}
</script>

<style lang="scss" scoped>
</style>
