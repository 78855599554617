<template lang="pug">
app-header
router-view
app-footer
</template>

<style lang="scss">
@import "assets/scss/common";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

</style>
<script>
import AppHeader from "@/components/layout/AppHeader";
import AppFooter from "@/components/layout/AppFooter";
import PageMain from "@/views/Home";

export default {
    components: {PageMain, AppHeader, AppFooter}
}
</script>
