import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import { messages } from "@/assets/locales";

const i18n = createI18n({
    locale: 'ru', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

createApp(App)
    .use(router)
    .use(i18n)
    .mount('#app')
