<template lang="pug">
section.news.content-area
    h3.warning {{ $t('pages.main.underDevelopment') }}
    .news__banner
        h1 {{ $t('pages.main.bannerTitle') }}
        h3 {{ $t('pages.main.bannerCaption') }}

</template>

<script>
export default {
  name: 'Home',
  mounted() {
    document.title = this.$t('pages.main.metaTitle')
  }
}
</script>

<style lang="scss" scoped>
.news {
    &__banner {
        background: center / cover url("~@/assets/img/pages/main/1.jpg");
        padding: 40px 200px;
        color: white;
    }
}
</style>
