<template lang="pug">
section.conditions.content-area
    h1 {{ $t('pages.tradingConditions.title') }}
    p {{ $t('pages.tradingConditions.contractsSpecification') }}
    table
      tr
        th {{ $t('pages.tradingConditions.table.tool') }}
        th {{ $t('pages.tradingConditions.table.spread') }}
        th {{ $t('pages.tradingConditions.table.contractSize') }}
        th {{ $t('pages.tradingConditions.table.limitStopLevel') }}
        th {{ $t('pages.tradingConditions.table.longCommission') }}
        th {{ $t('pages.tradingConditions.table.shortCommission') }}
      tr(v-for="contract in contracts" :key="contract.id")
        td {{ contract.tool }}
        td {{ contract.spread }}
        td {{ contract.contractSize }}
        td {{ contract.limitStopLevel }}
        td {{ contract.longCommission }}
        td {{ contract.shortCommission }}

</template>

<script>
export default {
  name: 'TradingConditions',
  data() {
    return {
      contracts: [
        {
          id: 1,
          tool: 'EUR/USD',
          spread: 1.4,
          contractSize: '1 000 EUR',
          limitStopLevel: 0,
          longCommission: '-7.00',
          shortCommission: '0.10'
        },
        {
          id: 2,
          tool: 'GBP/USD',
          spread: 2.1,
          contractSize: '1 000 GBP',
          limitStopLevel: 0,
          longCommission: '-5.50',
          shortCommission: '-2.30'
        },
        {
          id: 3,
          tool: 'USD/RUB',
          spread: '25 коп.',
          contractSize: '1 000 USD',
          limitStopLevel: 0,
          longCommission: '-450.00',
          shortCommission: '150.00'
        },
        {
          id: 4,
          tool: 'AUD/USD',
          spread: 2.3,
          contractSize: '1 000 AUD',
          limitStopLevel: 0,
          longCommission: '-2.50',
          shortCommission: '-2.40'
        },
        {
          id: 5,
          tool: 'EUR/CAD',
          spread: 2.1,
          contractSize: '1 000 USD',
          limitStopLevel: 0,
          longCommission: '-2.90',
          shortCommission: '-6.00'
        },
        {
          id: 6,
          tool: 'USD/JPY',
          spread: 1.8,
          contractSize: '1 000 USD',
          limitStopLevel: 0,
          longCommission: '-2.50',
          shortCommission: '-4.50'
        },
        {
          id: 7,
          tool: 'EUR/RUB',
          spread: '25 коп.',
          contractSize: '1 000 EUR',
          limitStopLevel: 0,
          longCommission: '-650.00',
          shortCommission: '200.00'
        },
        {
          id: 8,
          tool: 'NZD/USD',
          spread: 2.5,
          contractSize: '1 000 NZD',
          limitStopLevel: 0,
          longCommission: '-2.50',
          shortCommission: '-2.80'
        },
        {
          id: 9,
          tool: 'USD/CHF',
          spread: 2,
          contractSize: '1 000 USD',
          limitStopLevel: 0,
          longCommission: '0.50',
          shortCommission: '-6.00'
        },
        {
          id: 10,
          tool: 'GBP/JPY',
          spread: 4.6,
          contractSize: '1 000 GBP',
          limitStopLevel: 0,
          longCommission: '-2.50',
          shortCommission: '-5.50'
        },
        {
          id: 11,
          tool: 'EUR/GBP',
          spread: 2,
          contractSize: '1 000 GBP',
          limitStopLevel: 0,
          longCommission: '-4.70',
          shortCommission: '-0.70'
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
    table {
      width: 100%;
      text-align: center;
      th,td {
        border: 1px solid gray;
        padding: 5px;
      }
      th {
        background-color: gray;
        color: white;
      }
    }
</style>
