<template lang="pug">
header.header
    .header__content.content-area
        .header__logo Multirate limited
        ul.header__navigation
            li.header__navigation-item
                router-link(to="/") {{ $t('navigation.main') }}
            li.header__navigation-item
                router-link(to="/trading-conditions") {{ $t('navigation.tradingConditions') }}
            li.header__navigation-item
              router-link(to="/analytics") {{ $t('navigation.analytics') }}
            li.header__navigation-item
                router-link(to="/meta-trader") {{ $t('navigation.metaTrader') }}
            li.header__navigation-item
                router-link(to="/about") {{ $t('navigation.aboutCompany') }}
        select(v-model="$i18n.locale")
          option(v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale") {{ locale }}

</template>

<script>
export default {
  name: 'AppHeader',
}
</script>

<style lang="scss" scoped>
.header {
    background: $wafer;

    &__logo {
      background-color: $royal-blue;
      color: white;
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding: 17px;
    }

    &__content {
        display: flex;
    }

    &__navigation {
        padding: 0;
        list-style-type: none;
        width: 70%;
        display: flex;
        align-items: center;

        &-item {
            display: inline-block;
            margin: 0 20px;
            text-align: center;
        }
    }

    &__info {
        font-size: 12px;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    a {
        color: $eggplant;
    }

    .router-link-active {
        color: $royal-blue;
        font-weight: bold;
    }
}
</style>
