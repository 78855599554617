import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TradingConditions from '../views/TradingConditions.vue'
import MetaTrader from "../views/MetaTrader";
import About from "@/views/About";
import Analytics from "@/views/Analytics";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trading-conditions',
    name: 'TradingConditions',
    component: TradingConditions
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics
  },
  {
    path: '/meta-trader',
    name: 'MetaTrader',
    component: MetaTrader
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
