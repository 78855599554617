<template lang="pug">
section.analytics.content-area
    h1 {{ $t('pages.analytics.analyticsTitle') }}

    h2 {{ $t('pages.analytics.traderCalculator') }}
    p {{ $t('pages.analytics.traderCalculatorDescription') }}
    .calc
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.currencyPair') }}
        select
          option EUR/USD
          option GBP/USD
          option USD/JPY
          option USD/CHF
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.tradingAccountCurrency') }}
        select
          option EUR
          option USD
          option RUB
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.accountFunds') }}
        input(type="number" value=5000)
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.tradeVolumeInLots') }}
        input(type="number" value=1)
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.openingPrice') }}
        input(type="number" value=0.98624)
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.closingPrice') }}
        input(type="number" value=0.98638)
      .calc-option
        .label {{ $t('pages.analytics.calculatorLabels.dealDirection') }}
        select
          option {{ $t('pages.analytics.calculatorLabels.buy') }}
          option {{ $t('pages.analytics.calculatorLabels.sale') }}
      button.button.button--white {{ $t('buttons.calculate') }}

</template>

<script>
export default {
  name: 'Analytics',
}
</script>

<style lang="scss" scoped>
  .calc {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 75%;
    max-width: 1000px;
    background-color: #e8e1da;
    padding: 20px;
    &-option {
      margin-bottom: 15px;
      .label {
        font-size: 14px;
        margin-bottom: 5px;
      }

      input, select {
        font-size: 20px;
        width: 100%;
      }
    }
  }
</style>
