<template lang="pug">
section.about.content-area
    h1 {{ $t('pages.aboutCompany.title') }}
    p {{ $t('pages.aboutCompany.description') }}
</template>

<script>
export default {
  name: 'About',
  mounted() {
      document.title = this.$t('pages.aboutCompany.title')
  }
}

</script>

<style lang="scss" scoped>
</style>
