export const messages = {
    ru: {
        navigation: {
            main: 'Главная',
            tradingConditions: 'Торговые условия',
            analytics: 'Аналитика',
            metaTrader: 'МетаТрейдер',
            aboutCompany: 'О компании'
        },
        footer: {
            contacts: 'Контакты:',
            phone: 'Телефон:',
            workingHours: 'Режим работы:',
            workingDays: 'Рабочие дни: Пн-Пт',
            weekDays: 'Выходные: Сб-Вс',
            address: 'Физический рабочий адрес:',
            riskTitle: 'Предупреждение о рисках:',
            riskText: 'Наши услуги связаны со сложными производными продуктами, которые торгуются вне биржи. Эти продукты сопряжены с высоким риском быстрой потери денег из-за кредитного плеча и, следовательно, не подходят для всех инвесторов. Ни при каких обстоятельствах Multirate Limited не несет никакой ответственности перед каким-либо физическим или юридическим лицом за любые убытки или ущерб, полностью или частично вызванные, возникшие в результате или связанные с любой инвестиционной деятельностью. \n  Multirate Limited не предлагает услуги резидентам определенных юрисдикций, включая США, Иран, Северную Корею, Европу, Великобританию и другие.'
        },
        pages: {
            main: {
                metaTitle: 'Главная страница | Multirate Limited: торговая платформа MetaTrader 4 и аналитика',
                underDevelopment: 'Внимание! Сайт находится в режиме разработки. Некоторые функции могут быть недоступны.',
                bannerTitle: 'Multirate Limited — партнер вашего бизнеса.',
                bannerCaption: 'Мы предоставляем программное обеспечение для торговли и надежную аналитику.'
            },
            tradingConditions: {
                title: 'Торговые условия',
                contractsSpecification: 'Спецификация контрактов',
                table: {
                    tool: 'Инструмент',
                    spread: 'Спред',
                    contractSize: 'Размер контракта',
                    limitStopLevel: 'Уровень limit&stop',
                    longCommission: 'Комиссия за long',
                    shortCommission: 'Комиссия за short',
                }
            },
            analytics: {
                analyticsTitle: 'Аналитика',
                traderCalculator: 'Калькулятор трейдера',
                traderCalculatorDescription: 'Калькулятор трейдера рассчитывает данные по сделкам в режиме онлайн',
                calculatorLabels: {
                    currencyPair: 'Валютная пара',
                    tradingAccountCurrency: 'Валюта торгового счета',
                    accountFunds: 'Средства на счете',
                    tradeVolumeInLots: 'Объем сделки в лотах',
                    openingPrice: 'Цена открытия',
                    closingPrice: 'Цена закрытия',
                    dealDirection: 'Направление сделки',
                    sale: 'Продажа',
                    buy: 'Покупка'
                }
            },
            metaTrader: {
                metaTitle: 'МетаТрейдер | Multirate Limited: торговая платформа MetaTrader 4 и аналитика',
                title: 'MetaTrader 4 (МетаТрейдер 4)',
                subtitle: 'MetaTrader 4 – это единая торговая платформа',
                description: 'Торговая платформа MetaTrader 4 популярна среди трейдеров с любым уровнем подготовки. Платформа предлагает самые широкие возможности для торговли: продвинутый технический анализ, гибкую торговую систему, алгоритмический трейдинг и торговых советников. Функционал платформы обеспечивает потребности как новичков-любителей, так и профессионалов. Торговать на платформе MetaTrader 4 можно с персонального компьютера и мобильного телефона.'
            },
            aboutCompany: {
                title: 'О компании',
                description: 'Компания Multirate Limited зарегистрирована 04.04.2017 Белизе, по адресу: 56 Daly Street, Balize, Belize City, Bilize District, P.O. Box 1825, регистрационный номер компании 166059. Компания Multirate Limited владеет правами на использование торгового терминала Meta Trader 4 (MT-4).'
            }
        },
        buttons: {
            openAccount: 'Открыть счет',
            calculate: 'Рассчитать'
        }
    },
    en: {
        navigation: {
            main: 'Main',
            tradingConditions: 'Trading conditions',
            analytics: 'Analytics',
            metaTrader: 'MetaTrader',
            aboutCompany: 'About company'
        },
        footer: {
            contacts: 'Contacts:',
            phone: 'Phone:',
            workingHours: 'Working hours:',
            workingDays: 'Workdays: Mon-Fri',
            weekDays: 'Weekdays: Sat-San',
            address: 'Physical/Business address:',
            riskTitle: 'Risk warning:',
            riskText: 'Our services are related to complex derivative products traded off-exchange. These products are associated with a high risk of rapid money loss due to leverage and, therefore, are not suitable for all investors. Under no circumstances is Multirate Limited liable to any individual or legal entity for any loss or damage caused in whole or in part, resulting from or related to any investment activity. \n Multirate Limited does not offer services to residents of certain jurisdictions, including the USA, Iran, North Korea, Europe, the UK, and others.'
        },
        pages: {
            main: {
                metaTitle: 'Main page | Multirate Limited: MetaTrader 4 trading platform and analytics',
                underDevelopment: 'Attention! The site is under development. Some features may be unavailable.',
                bannerTitle: 'Multirate Limited is a partner for your business.',
                bannerCaption: 'We provide trading software and reliable analytics.'
            },
            tradingConditions: {
                title: 'Trading conditions',
                contractsSpecification: 'Contract specification',
                table: {
                    tool: 'Tool',
                    spread: 'Spread',
                    contractSize: 'Contract size',
                    limitStopLevel: 'limit&stop level',
                    longCommission: 'Long commission',
                    shortCommission: 'Short commission',
                }
            },
            analytics: {
                analyticsTitle: 'Analytics',
                traderCalculator: 'Trader calculator',
                traderCalculatorDescription: 'The trader\'s calculator calculates trade data online',
                calculatorLabels: {
                    currencyPair: 'Currency pair',
                    tradingAccountCurrency: 'Trading account currency',
                    accountFunds: 'Account funds',
                    tradeVolumeInLots: 'Trade volume in lots',
                    openingPrice: 'Opening price',
                    closingPrice: 'Closing prise',
                    dealDirection: 'Deal direction',
                    sale: 'Sale',
                    buy: 'Buy'
                }
            },
            metaTrader: {
                metaTitle: 'MetaTrader | Multirate Limited: MetaTrader 4 trading platform and analytics',
                title: 'MetaTrader 4',
                subtitle: 'MetaTrader 4 is a universal trading platform',
                description: 'The MetaTrader 4 platform is popular among traders with any level of training. MetaTrader 4 provides clients with the best trading opportunities. These include advanced tools of technical analysis, flexible trading system, algorithmic trading, and Expert Advisors. The platform satisfies the needs of amateurs and professionals alike. Trading on MetaTrader 4 is available on desktop and mobile.'
            },
            aboutCompany: {
                title: 'About company',
                description: 'Multirate Limited was registered on 04/04/2017 in Belize, at the address: 56 Daly Street, Balize, Belize City, Bilize District, P.O. Box 1825, company registration number 166059. Multirate Limited owns the rights to use the Meta Trader 4 (MT-4) trading terminal.'
            }
        },
        buttons: {
            openAccount: 'Open an account',
            calculate: 'Calculate'
        }
    },
}
